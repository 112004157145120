#painelWrapper > li a:hover,
#painelWrapper > li.current > a {
  color: #FFF;
  background: #888;
}

#painelWrapper > li > a:hover::before,
#painelWrapper > li.current > a::before {
  display: block;
}

#painelWrapper > li.current div {
  display: block;
}
